import '@material/web/radio/radio.js'
import '@material/web/switch/switch.js'
import '@material/web/fab/fab.js'
import '@material/web/button/filled-button.js'
// import '@material/web/button/outlined-link-button.js'
import '@material/web/button/outlined-button.js'
import '@material/web/checkbox/checkbox.js'
import '@material/web/icon/icon.js'
import '@material/web/tabs/tabs.js'
import '@material/web/dialog/dialog.js'
import '@material/web/ripple/ripple.js'
import '@material/web/divider/divider.js'
// import '@material/web/linearprogress/linear-progress.js'
// import '@material/web/circularprogress/circular-progress.js'
import '@material/web/slider/slider.js'

import '@material/web/iconbutton/standard-icon-button.js'

// This was causing a conflicty so I removed it: import '@material/mwc-drawer/mwc-drawer.js'
//import '@material/mwc-top-app-bar/mwc-top-app-bar.js'
// We're going to use the Fixed version of the MWC Top app bar
import '@material/mwc-top-app-bar-fixed/mwc-top-app-bar-fixed.js'
import '@material/mwc-icon-button/mwc-icon-button.js'
import '@material/mwc-button/mwc-button.js'

import '@material/mwc-select/mwc-select.js'
import '@material/mwc-textfield/mwc-textfield.js'
import '@material/mwc-textarea/mwc-textarea.js'


import NProgress from 'nprogress/nprogress.js';
NProgress.start();
document.addEventListener("DOMContentLoaded", function(event) {
  NProgress.done();
});

window.addEventListener("beforeunload", function(event) {
  NProgress.set(0.2);
  NProgress.start();
});

import mdtoast from '@dmuy/toast'
// Set the variable to window for access
window.mdtoast = mdtoast;
